import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {logIn} from '@pages/auth/login/actions';
import Path from '@routes/path';
import backgroundImage from '../../../resources/images/loginBackground.png';
import logo from '../../../resources/images/logo.png';
import MainLayout from '@pages/mainLayout';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  border-radius: 10px;
  backdrop-filter: blur(4px);
  padding: 4% 4%;
  margin-bottom: 5%;
  margin-top: 5%;
  background: radial-gradient(
    at top left,
    rgba(255, 158, 158, 0.2) 0%,
    rgba(204, 204, 204, 0.3) 30%,
    rgba(204, 204, 204, 0.3) 80%,
    rgba(255, 158, 158, 0.2) 100%
  );
  @media (min-width: 768px) {
    width: 30%;
  }
`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 5%;
  width: 100%;
`;
const Title = styled.p`
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s5};
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 5%;
  font-family: 'Raleway', sans-serif;
`;

const Input = styled.input`
  display: block;
  padding: 3% 5%;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: ${({theme}) => theme.text.s8};
  color: ${({theme}) => theme.text.dark};
  border-radius: 8px;
  border: ${({theme}) => '1px solid ' + theme.border};
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  @media (min-width: 768px) {
    width: 60%;
    padding: 2% 3%;
  }
`;
const Button = styled.button`
  opacity: ${({disabled}) => (disabled ? 0.7 : 1)};
  background: ${({theme}) => theme.text.dark};
  border-radius: 10px;
  border: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  display: flex;
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s7};
  padding: 3% 20%;
  justify-content: center;
  font-family: 'Raleway', sans-serif;
  align-items: center;
  width: fit-content; // Ensure width is based on content
  align-self: center;
  outline: none;
  ${({disabled}) =>
    disabled
      ? ''
      : `    
        cursor: pointer;
    transition: background-color 0.2s,
    transform 0.2s;
    `};
  &:hover {
    background-color: ${({theme, disabled}) =>
      disabled ? undefined : theme.tertiary};
  }
  &:active {
    background-color: ${({theme, disabled}) =>
      disabled ? undefined : theme.tertiary};
    transform: ${({disabled}) => (disabled ? undefined : 'scale(0.98)')};
  }

  &:focus {
    box-shadow: ${({theme, disabled}) =>
      disabled ? undefined : '0 0 0 2px ' + theme.tertiary + '77'};
  }

  @media (min-width: 768px) {
    padding: 1.5% 10%;
    margin-top: 5%;
  }
`;
const Logo = styled.img`
  display: flex;
  height: 70px;
  align-self: center;
`;
const mandatoryFields = ['username', 'password'];

const LoginForm = () => {
  const navigation = useNavigate();
  const {t} = useTranslation();

  const [form, updateForm] = useState<any>({
    username: '',
    password: '',
  });
  const [formComplete, setFormComplete] = useState(false);

  const handleChange = (key: string, value: string) => {
    updateForm({
      ...form,
      [key]: value,
    });
  };
  useEffect(() => {
    let _formComplete = true;
    for (let index = 0; index < mandatoryFields.length; index++) {
      const field = mandatoryFields[index];
      if (!form[field] || form[field].length <= 0) {
        setFormComplete(false);
        _formComplete = false;
        break;
      }
    }
    if (_formComplete) {
      setFormComplete(true);
    }
  }, [form]);

  const submit = async () => {
    await logIn({email: form.username, password: form.password});
    navigation(Path.MyProducts.Root);
  };

  return (
    <MainLayout backgroundImage={backgroundImage}>
      <Container>
        <FormContainer>
          <Logo src={logo} alt="Logo" />
          <Title>Login</Title>
          <InputContainer>
            <Input
              onChange={e => handleChange('username', e.target.value)}
              type="text"
              placeholder={t('login.username')}
              id="username"
            />
          </InputContainer>
          <InputContainer>
            <Input
              onChange={e => handleChange('password', e.target.value)}
              type="password"
              placeholder={t('login.password')}
              id="password"
            />
          </InputContainer>
          <Button disabled={!formComplete} onClick={submit}>
            {t('login.signIn')}
          </Button>
        </FormContainer>
      </Container>
    </MainLayout>
  );
};
export default LoginForm;
