import * as React from 'react';
import {SVGProps} from 'react';
const SaveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={27}
    viewBox={'0 0 24 24'}
    {...props}
    fill={'none'}>
    <path
      d="M4 12.6111L8.92308 17.5L20 6.5"
      stroke={props.fill || '#000000'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SaveIcon;
