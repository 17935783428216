import {colors} from './colors';

/**
 * This can be used to create different themes
 * like dark and light. It can be then connected to
 * React context and controlled via a setting flag.
 */

const lightTheme = {
  primary: colors.primary,
  secondary: colors.secondary,
  tertiary: colors.tertiary,
  homeBackground: '#FFFFFF',
  border: '#D9D9D9',
  skeletonBackground: '#aaa',
  overlay: 'rgba(0,0,0,0.3)',
  alert: {
    backgroundPrimary: colors.primary,
    textPrimary: '#FFFFFF',
  },
  text: {
    textPrimary: '#242424',
    dark: '#242424',
    error: '#a80000',
    light: '#FFFFFF',
    placeholder: '#FFFFFF',
    borderLight: '#FFFFFF',
    borderFocus: '#80CBC4',
    lightBlue: '#007AFF',
    grey: '#888',
    black: '#000',
    s1: '2.75rem', // Adjust based on your design needs
    s2: '2rem',
    s3: '1.75rem',
    s4: '1.56rem',
    s5: '1.43rem',
    s6: '1.25rem',
    s7: '1.06rem',
    s8: '1rem',
    s85: '0.97rem',
    s89: '0.89rem',
    s9: '0.875rem',
    s10: '0.75rem',
    s11: '0.625rem',
    s12: '0.5rem',
  },
};

export default lightTheme;
