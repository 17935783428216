import React from 'react';
import styled from 'styled-components';
import Header from '@components/header';
import Footer from '@components/footer';

const PageContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  ${({backgroundImage}) =>
    backgroundImage &&
    `
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
  position: relative;
    `}
`;

const MainContent = styled.main`
  flex: 1;
`;

const MainLayout: React.FC<any> = ({children, backgroundImage}: any) => {
  return (
    <PageContainer backgroundImage={backgroundImage}>
      <Header />
      <MainContent>{children}</MainContent>
      <Footer />
    </PageContainer>
  );
};

export default MainLayout;
