import React, {type CSSProperties} from 'react';
import styled from 'styled-components';
import ErrorIcon from '../../resources/icons/error';
import {useTranslation} from 'react-i18next';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

const Description = styled.p`
  font-size: ${({theme}) => theme.text.s8}px;
  color: ${({theme}) => theme.text.dark};
  max-width: 90%;
  margin: 1% 0;
  text-align: center;
`;
const Button = styled.button`
  background: ${({theme}) => theme.text.dark};
  border-radius: 10px;
  border: none;
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s7};
  cursor: pointer;
  padding: 2% 10%;
  display: inline-flex; // Use inline-flex for better alignment
  justify-content: center;
  align-items: center;
  width: fit-content; // Ensure width is based on content
  margin-top: 3%; // Adjusted for simplicity
  align-self: center;
  outline: none;
  transition:
    background-color 0.2s,
    transform 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.tertiary};
  }

  &:active {
    background-color: ${({theme}) => theme.tertiary};
    transform: scale(0.98); /* Slightly scale down the button */
  }

  &:focus {
    box-shadow: ${({theme}) => '0 0 0 2px ' + theme.tertiary + '77'};
  }

  @media (min-width: 768px) {
    padding: 1% 5%;
  }
`;
const ErrorView = ({
  onRefresh,
  style,
}: {
  onRefresh: () => void;
  style?: CSSProperties;
}) => {
  const {t} = useTranslation();

  const RefreshHandler = async () => {
    if (!onRefresh) {
      return;
    }
    await onRefresh();
  };

  return (
    <Container style={style}>
      <ErrorIcon width={'30%'} height={'30%'} />
      <Description> {t('serverError.description')} </Description>
      <Button onClick={RefreshHandler}>{t('serverError.tryAgain')}</Button>
    </Container>
  );
};

export default ErrorView;
