import React, {useRef, useState} from 'react';
import styled, {IStyledComponent, useTheme} from 'styled-components';
import {LazyLoadImage} from 'react-lazy-load-image-component';
// Import the stylesheet for default placeholder and animations
import 'react-lazy-load-image-component/src/effects/blur.css';
import Loader from '@components/loader';
import {useTranslation} from 'react-i18next';
import {type ProductProps} from '../../resources/interfaces/components/product';
import SaveIcon from '../../resources/icons/save';
import EditIcon from '../../resources/icons/edit';
import {Tooltip, TooltipRefProps} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const ProductContainer = styled.div`
  display: flex;
  position: relative;
  border: 1px solid;
  border-color: ${({theme}) => theme.border};
  border-radius: 10px;
  width: 100%;
  margin: 1% auto;
  padding: 1% 5%;
  align-items: center;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    width: 50%;
    padding: 1% 1%;
  }
`;

const ImageContainer = styled.div`
  width: 80%;
  max-width: 200px;
  aspect-ratio: 1;
  border: ${({theme}) => `1px solid ${theme.border}`};
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;

  @media (min-width: 768px) {
    width: 13vw;
    margin: 0 10px;
  }
`;

const Image: IStyledComponent<'web', any> = styled(LazyLoadImage)`
  width: 100%;
  aspect-ratio: 1;
  border-radius: 10px;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 768px) {
    padding: 5% 0 5% 5%;
    width: auto;
  }
`;

const Title = styled.p`
  font-size: ${({theme}) => theme.text.s6};
  font-weight: bold;
  color: ${({theme}) => theme.text.dark};
  margin: 0;
`;

const Description = styled.p`
  font-size: ${({theme}) => theme.text.s8};
  color: ${({theme}) => theme.text.dark};
`;

const Button = styled.button`
  background: ${({theme}) => theme.tertiary};
  border-radius: 10px;
  border: none;
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s7};
  cursor: pointer;
  padding: 2% 5%;
  display: inline-flex; // Use inline-flex for better alignment
  justify-content: center;
  align-items: center;
  width: fit-content; // Ensure width is based on content
  margin-top: 5%; // Adjusted for simplicity
  align-self: center;
  outline: none;
  transition:
    background-color 0.2s,
    transform 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.tertiary};
  }

  &:active {
    background-color: ${({theme}) => theme.tertiary};
    transform: scale(0.98); /* Slightly scale down the button */
  }

  &:focus {
    box-shadow: ${({theme}) => '0 0 0 2px ' + theme.tertiary + '77'};
  }

  @media (min-width: 768px) {
    margin-top: 0; // Adjust top margin for larger screens
    align-self: flex-start;
  }
`;

const SaveButton = styled(Button)`
  background: transparent;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.border};
  color: ${({theme}) => theme.text.dark};
  box-shadow: 0 4px 8px ${({theme}) => theme.tertiary + '77'};
  padding: 20px;
`;

const LoaderContainer = styled.div`
  width: 80%;
  max-width: 200px;
  aspect-ratio: 1;
  position: absolute;
  @media (min-width: 768px) {
    width: 13vw;
    margin: 0 10px;
  }
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: fit-content;
  min-width: 70%;
  border: ${({theme}) => '1px solid ' + theme.border};
  border-radius: 10px;
`;

const Input = styled.input`
  outline: none;
  padding: 3% 5%;
  font-size: ${({theme}) => theme.text.s8};
  color: ${({theme}) => theme.text.dark};
  border-radius: 10px;
  border: none;
  flex: 1;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({theme}) => theme.text.s10};
`;
const TooltipItem = styled(Tooltip)`
  background: ${({theme}) => theme.tertiary} !important;
  border-radius: 10px !important;
`;
const Badge = styled.div`
  background: ${({theme}) => theme.tertiary};
  border-radius: 50%;
  width: ${({theme}) => theme.text.s7};
  height: ${({theme}) => theme.text.s7};
  position: absolute;
  top: -${({theme}) => theme.text.s12};
  right: -${({theme}) => theme.text.s12};
`;

/**
 * Represents a product item in a catalogue, featuring an image, title, description, and an action button.
 * This component provides a concise and visually appealing summary of a product, making it easier for users
 * to browse through product listings.
 *
 * @component
 *
 * @param {ProductProps} props - The properties passed to the component.
 * @param {string} props.title - The title of the product.
 * @param {string} props.description - A brief description of the product.
 * @param {string} props.src - The source URI of the product image.
 * @param {Function} props.onClick - Function to execute when the action button is clicked.
 * @param {CSSProperties} [props.style] - Optional styling for the product container.
 *
 * @returns {JSX.Element} The rendered Product component.
 */
const Product: React.FC<ProductProps> = ({
  title,
  description,
  src,
  containerStyle,
  onClick,
  isSetTitle,
  isBadge,
}: ProductProps): JSX.Element => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [text, setText] = useState('');
  const tooltipRef = useRef<TooltipRefProps>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const showToolTip = () => {
    tooltipRef?.current?.open({
      content: (
        <div style={{display: 'flex', flexDirection: 'column', padding: '1%'}}>
          <span>{t('product.tipPart1')}</span>
          <span>{t('product.tipPart2')}</span>
          <span>{t('product.tipPart3')}</span>
        </div>
      ),
    });
  };

  const onClickButtonHandler = (event: any) => {
    event.preventDefault();
    if (isSetTitle && (inputRef?.current?.value || '').trim() === '') {
      showToolTip();
      return;
    }
    if (tooltipRef) {
      tooltipRef?.current?.close();
    }
    onClick && onClick(inputRef?.current?.value || '');
  };

  const handleTextChange = event => {
    setText(event.target.value);
  };

  return (
    <ProductContainer style={containerStyle}>
      <ImageContainer>
        {isLoading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        <Image
          alt={title}
          src={src}
          effect="blur"
          onLoad={() => {
            setIsLoading(false);
          }}
        />
      </ImageContainer>
      <ContentContainer>
        <div>
          {isSetTitle ? (
            <InputContainer data-tooltip-id="my-tooltip">
              <Input
                onChange={handleTextChange}
                ref={inputRef}
                placeholder="Product Name"
              />
              {(text || '').trim() !== '' && (
                <IconContainer>
                  <SaveIcon
                    width={theme.text.s5}
                    height={theme.text.s5}
                    fill={theme.text.borderFocus}
                  />
                </IconContainer>
              )}
            </InputContainer>
          ) : (
            <Title>{title}</Title>
          )}
          <Description>{description}</Description>
        </div>
        <Button onClick={onClickButtonHandler}>{t('product.button')}</Button>
      </ContentContainer>
      <TooltipItem imperativeModeOnly ref={tooltipRef} id="my-tooltip" />
      {isBadge && <Badge />}
    </ProductContainer>
  );
};

Product.defaultProps = {
  containerStyle: {},
};

export default Product;
