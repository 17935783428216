import React from 'react';
import styled from 'styled-components';
import logo from '../../resources/images/logo.png';
import FacebookIcon from '../../resources/icons/social/facebook';
import InstagramIcon from '../../resources/icons/social/instagram';
import LinkedinIcon from '../../resources/icons/social/linkedin';
import AppleIcon from '../../resources/icons/social/apple';
import GooglePlayIcon from '../../resources/icons/social/googlePlay';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import {links} from '../../api/api';

const FooterContainer = styled.footer`
  width: 100%;
  background-color: ${({theme}) => theme.text.dark};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5% 20% 5% 10%;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 768px) {
    padding: 10% 0;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const Logo = styled.img`
  height: 4rem;
  width: auto;
  @media (max-width: 768px) {
    height: 6rem;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
const DownloadAppButton = styled.button`
  border-color: ${({theme}) => theme.text.light};
  border-radius: 10px;
  padding: 3% 1%;
  background-color: transparent;
  color: ${({theme}) => theme.text.light};
  letter-spacing: 1px;
  font-size: ${({theme}) => theme.text.s9};
  min-width: 12rem;
  margin-bottom: 5%;
`;

const FooterLink = styled.a`
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s10};
  letter-spacing: 0.1em;
  margin: 5px 0;
  text-decoration: none;
`;
const SocialIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 5%;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 5%;
  @media (max-width: 768px) {
    justify-content: space-evenly;
  }
`;
const Separator = styled.div`
  width: 60%;
  height: 1px;
  background-color: ${({theme}) => theme.border};
  margin: 10% auto;
`;

const CopyrightText = styled.p`
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s9};
  letter-spacing: 0.1em;
  text-align: center;
  margin-right: -20%;
  padding-top: 5%;
  @media (max-width: 768px) {
    margin-right: 0;
  }
`;
const Footer = () => {
  const {isMobile} = useWindowDimensions();

  return (
    <FooterContainer>
      <FooterContent>
        <LogoContainer>
          <Logo src={logo} alt="QRystal Logo" />
        </LogoContainer>
        <ContentGroup>
          <FooterLink href={links.THE_COLLECTION}>THE COLLECTION</FooterLink>
          <FooterLink href={links.HOW_IT_WORKS}>HOW IT WORKS</FooterLink>
          <FooterLink href={links.CONTACT_US}>CONTACT US</FooterLink>
          <FooterLink href={links.ABOUT_US}>ABOUT US</FooterLink>
        </ContentGroup>
        {isMobile && <Separator />}
        <ContentGroup>
          <FooterLink href={links.PRIVACY}>Privacy & Policy</FooterLink>
          <FooterLink href={links.WARRANTY}>Warranty</FooterLink>
          <FooterLink href={links.ORDERING_PAYMENT_GUIDE}>
            Payments & Orders
          </FooterLink>
          <FooterLink href={links.RETURN_REFUND}>Returns & Refund</FooterLink>
        </ContentGroup>
        {isMobile && <Separator />}
        <ContentGroup>
          <FooterLink href="mailto:info@qrystal.com">
            info@qrystal.com
          </FooterLink>
          <FooterLink href="#">1234567890</FooterLink>
          <SocialIconsContainer>
            <FacebookIcon />
            <InstagramIcon />
            <LinkedinIcon />
          </SocialIconsContainer>
        </ContentGroup>
        {isMobile && <Separator />}
        <ContentGroup>
          <DownloadAppButton>Download the app</DownloadAppButton>
          <Row>
            <AppleIcon
              style={{
                marginRight: '5%',
              }}
            />
            <GooglePlayIcon />
          </Row>
        </ContentGroup>
      </FooterContent>
      <CopyrightText>© 2024 QRystal. All Rights Reserved</CopyrightText>
    </FooterContainer>
  );
};
export default Footer;
