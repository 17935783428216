import React, {useEffect, useRef, useState} from 'react';
import useScrollTop from '../../../utils/hooks/useScrollTop';
import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import Memory from '@components/memory';
import Loader from '@components/loader';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Memory as MemoryType} from '../../../resources/interfaces/api/memories/memoriesResponse';
import {useLocation, useNavigate} from 'react-router-dom';
import Path from '@routes/path';
import {ProductItemType} from '@resources/interfaces/api/necklaces';
import {getProducts} from '@pages/content/myProducts/actions';
import ChoosePasscodeType from '@components/choosePasscodeType';
import {QrStatus} from '@utils/enums';
import Popup from 'reactjs-popup';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import {getMemoriesCount} from '@utils/helper';
import {
  getMemoriesByCode,
  setQrPasscode,
} from '@pages/content/myMemories/actions';
import emptyImage from '../../../resources/images/memories.png';
import ErrorView from '@components/errorView';
import MyMemoriesSkeleton from '@components/myMemoriesSkeleton';
import ChangePasscodeIcon from '../../../resources/icons/changePasscode';
import {Theme} from '@resources/interfaces/colors';
import MainLayout from '@pages/mainLayout';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3%;
`;
const Title = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s5};
  letter-spacing: 2px;
  text-align: center;
  align-self: center;
  font-weight: 500;
  padding-bottom: 3%;
`;
const ContentContainer = styled.div`
  border: ${({theme}) => '1px solid ' + theme.border};
  border-radius: 15px;
  width: 100%;
  padding: 3% 2%;
  @media (min-width: 768px) {
    width: 60%;
  }
`;
const MemoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MemoryName = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s5};
  letter-spacing: 1px;
  font-weight: bold;
  flex: 1;
  padding-left: 3%;
`;
const ChangeIconContainer = styled.button`
  border-radius: 50%;
  background-color: ${({theme}) => theme.border + '22'};
  align-items: center;
  align-self: center;
  border: none;
  padding: 1%;
  cursor: pointer;
  outline: none;
  transition:
    background-color 0.2s,
    transform 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.tertiary + '22'};
  }

  &:active {
    background-color: ${({theme}) => theme.tertiary + '22'};
    transform: scale(0.98); /* Slightly scale down the button */
  }

  &:focus {
    box-shadow: ${({theme}) => '0 0 0 2px ' + theme.tertiary + '22'};
  }
`;

const CreateNewMemoryButton = styled.button`
  background: ${({theme}) => theme.text.dark};
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s8};
  border-radius: 10px;
  padding: 1% 5%;
  border: none;
  margin-top: 5%;
  cursor: pointer;
  outline: none;
  transition:
    background-color 0.2s,
    transform 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.tertiary};
  }

  &:active {
    background-color: ${({theme}) => theme.tertiary};
    transform: scale(0.98); /* Slightly scale down the button */
  }

  &:focus {
    box-shadow: ${({theme}) => '0 0 0 2px ' + theme.tertiary + '77'};
  }
`;

const Pagination = styled.div`
  margin-top: 20px;
  font-size: ${({theme}) => theme.text.s8};
  color: ${({theme}) => theme.text.dark};
`;
const LoaderContainer = styled.div`
  width: 80%;
  max-width: 200px;
  aspect-ratio: 1;
  position: absolute;
  @media (min-width: 768px) {
    width: 13vw;
    margin: 0 10px;
  }
`;
const ImageContainer = styled.div`
  width: 80%;
  max-width: 200px;
  aspect-ratio: 1;
  border: ${({theme}) => `1px solid ${theme.border}`};
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;

  @media (min-width: 768px) {
    width: 13vw;
    margin: 0 10px;
  }
`;

const Image = styled(LazyLoadImage)`
  width: 100%;
  aspect-ratio: 1;
  border-radius: 10px;
`;
const MemoriesLisContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5% 0;
`;
const EmptyText = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s8};
  letter-spacing: 1px;
  text-align: center;
  align-self: center;
  padding-top: 5%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    width: 60%;
  }
`;
const EmptyImageContainer = styled.div`
  width: 70%;
  aspect-ratio: 1.12;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    width: 30%;
  }
`;
const EmptyImage = styled.img`
  width: 100%;
  height: 100%;
`;

/**
 * MyMemories Screen Component
 *
 * This component represents the "My Memories" page in the application, where users can view a list
 * of their saved memories.
 *
 * Props: None
 *
 * @returns {JSX.Element} The rendered MyMemories screen component.
 */
const MyMemories = (): JSX.Element => {
  const theme = useTheme() as Theme;
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {product}: {product: ProductItemType} = useLocation().state || {};
  const [isImageLoading, setIsImageLoading] = useState(true);
  const {isMobile} = useWindowDimensions();
  const [isChoosePasscodeVisible, setIsChoosePasscodeVisible] = useState(false);
  const popupRef = useRef<any>(null);
  const [data, setData] = useState<MemoryType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  useScrollTop();
  const initPage = async () => {
    setData([]);
    setIsError(false);
    setIsLoading(true);
    const res = await getMemoriesByCode(product?.qrcode_id || '');
    setIsLoading(false);
    if (!res) {
      setIsError(true);
      return;
    }
    setData(res);
  };
  useEffect(() => {
    if (product?.is_new) {
      getProducts();
    }
    if (product?.is_passcode_type_updated === '0') {
      setIsChoosePasscodeVisible(true);
    }
    initPage();
  }, []);

  const onCreateMemoryPress = () => {
    navigate(Path.Memories.createMemory, {
      state: {
        product,
      },
    });
  };
  const onChoosePasscodeTypeHandler = async (label: QrStatus) => {
    if (QrStatus.PASSCODE === label) {
      setIsChoosePasscodeVisible(false);
      return;
    }
    const res = await setQrPasscode({
      passcode_type: label,
      temp_password: '',
      qrcode_id: +product.qrcode_id,
    });
    if (res) {
      setIsChoosePasscodeVisible(false);
    }
  };
  const onSubmitPasscodeHandler = async (passcode: string) => {
    const res = await setQrPasscode({
      qrcode_id: +product.qrcode_id,
      passcode,
      temp_password: '',
      passcode_type: QrStatus.PASSCODE,
    });
    setIsLoading(false);
    if (!res) {
      setIsError(true);
      return;
    }
    setIsChoosePasscodeVisible(false);
  };

  const onMemoryClick = (memory: MemoryType) => {
    navigate(Path.Memories.memoryDetails, {
      state: {
        memory,
      },
    });
  };
  return (
    <MainLayout>
      <Container>
        <Title>{t('myMemories.title')}</Title>
        <ContentContainer>
          <MemoryContainer>
            <ImageContainer>
              {isImageLoading && (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              )}
              <Image
                alt={'Memory'}
                src={product?.image || ''}
                effect="blur"
                onLoad={() => {
                  setIsImageLoading(false);
                }}
              />
            </ImageContainer>
            <MemoryName>{product?.nickname || product?.name || ''}</MemoryName>
            {/*<ChangeIconContainer*/}
            {/*  onClick={() => setIsChoosePasscodeVisible(true)}>*/}
            {/*  <ChangePasscodeIcon*/}
            {/*    width={theme.text.s2}*/}
            {/*    height={theme.text.s2}*/}
            {/*    fill={theme.primary}*/}
            {/*  />*/}
            {/*</ChangeIconContainer>*/}
          </MemoryContainer>
          <MemoriesLisContainer>
            {isError ? (
              <ErrorView onRefresh={initPage} />
            ) : isLoading ? (
              <MyMemoriesSkeleton />
            ) : (data || []).length === 0 ? (
              <div>
                <EmptyImageContainer>
                  <EmptyImage src={emptyImage} />
                </EmptyImageContainer>
                <EmptyText>{t('myMemories.empty')}</EmptyText>
              </div>
            ) : (
              (data || []).map((memory, index) => (
                <Memory
                  onClick={() => onMemoryClick(memory)}
                  key={memory.id}
                  title={memory.title}
                  date={memory.date}
                  containerStyle={{
                    borderTopWidth: index !== 0 ? 0 : undefined,
                  }}
                />
              ))
            )}
          </MemoriesLisContainer>
          {!isLoading && !isError && (
            <BottomContainer>
              <Pagination>
                {data?.length}
                {` / `}
                {getMemoriesCount()}
              </Pagination>
              {data?.length !== getMemoriesCount() && (
                <CreateNewMemoryButton onClick={onCreateMemoryPress}>
                  {t('myMemories.createMemory')}
                </CreateNewMemoryButton>
              )}
            </BottomContainer>
          )}
        </ContentContainer>
        <Popup
          ref={popupRef}
          contentStyle={{
            borderRadius: 15,
            width: isMobile ? '85%' : undefined,
          }}
          modal
          open={isChoosePasscodeVisible}
          position="center center">
          <ChoosePasscodeType
            onChoose={onChoosePasscodeTypeHandler}
            onSubmitPasscode={onSubmitPasscodeHandler}
            onClose={() => setIsChoosePasscodeVisible(false)}
            selectedType={product?.passcode_type || QrStatus.NO_PASSCODE}
          />
        </Popup>
      </Container>
    </MainLayout>
  );
};

export default MyMemories;
